import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://www.collegetools.io/auth/check', { withCredentials: true })
      .then(response => {
        setLoggedIn(response.data.isAuthenticated);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {loggedIn ? 'Logged In' : 'Not Logged In'}
    </div>
  );
}

export default App;
